import { Injectable } from '@angular/core';
import * as fromRouter from '@app/root-store/router/router.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import * as fromActions from './newsletter-preview.actions';
import * as fromReducer from './newsletter-preview.reducer';

import { NewsletterService } from '@app/core/services/newsletter.service';
import { NewsletterConfidentalNoteDialogContainer } from '@app/newsletter-new/dialogs/newsletter-confidental-note-dialog/newsletter-confidental-note-dialog.container';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';


@Injectable()
export class NewsletterPreviewEffects {
	public openConfirmDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.openConfirmDialog),
				map(({ campaign, isSubscribed }) => {
					const data: ConfirmDialogData = {
						title: `${isSubscribed ? 'Unsubscribe' : 'Subscribe'}`,
						messages: [`Are you sure you want to  ${isSubscribed ? 'unsubscribe from' : 'subscribe to'} <strong>${campaign.name}</strong> newsletter campaign?`],
						confirmButtonType: isSubscribed ? 'warning' : 'primary',
						confirmButtonLabel: isSubscribed ? 'Yes, unsubscribe' : 'Yes, subscribe',
						cancelButtonLabel: 'Cancel'
					}
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data
					});
					return { dialog, campaign, isSubscribed };
				}),
				switchMap(({ dialog, campaign, isSubscribed }) =>
					dialog.afterClosed().pipe(
						filter(confirmed => !!confirmed),
						map((confirmed) => {
							return isSubscribed ? fromActions.subscription.unsubscribeRequest({ campaignId: campaign.id }) : fromActions.subscription.subscribeRequest({ campaignId: campaign.id });
						})
					)
				)
			), 
		{ dispatch: true }
	);


	public isSubscribedRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.isSubscribedRequest),
				switchMap(({campaignId}) =>
					this.newsletterService.isSubscribed(campaignId).pipe(
						map((isSubscribed) => fromActions.subscription.isSubscribedSuccess({ isSubscribed })),
						catchError(({ message }) => of(fromActions.subscription.isSubscribedFailure({ error: message })))
					)
				),
			),
		{ dispatch: true }
	);

	public subscribeRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.subscribeRequest),
				switchMap(({campaignId}) =>
					this.newsletterService.subscribe(campaignId).pipe(
						map((isSubscribed) => fromActions.subscription.subscribeSuccess()),
						catchError(({ message }) => of(fromActions.subscription.subscribeFailure({ error: message })))
					)
				),
			),
		{ dispatch: true }
	);

	public unsubscribeRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.unsubscribeRequest),
				switchMap(({campaignId}) =>
					this.newsletterService.unsubscribe(campaignId).pipe(
						map((isSubscribed) => fromActions.subscription.unsubscribeSuccess()),
						catchError(({ message }) => of(fromActions.subscription.unsubscribeFailure({ error: message })))
					)
				),
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService,
	) {}
}

import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/user-settings/store/campaign-subscriptions/campaign-subscriptions.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
export interface CampaignListModel {campaignId: number, campaignName: string, isUnsubscribed: boolean,
  allowToSubscribe:    boolean
  allowToUnsubscribe:    boolean}
export const adapter: EntityAdapter<CampaignListModel> = createEntityAdapter<CampaignListModel>({
  selectId: (e) => e.campaignId
});
export interface State {
  data: EntityState<CampaignListModel>;
}

export const initialState: State = {
  data: adapter.getInitialState()
};

export const campaignSubscriptionsReducer = createReducer(
  initialState,
  on(fromActions.list.success, (state, {data}) => ({
    ...state,
    data:  adapter.setAll(data, state.data),
  })),
  on(fromActions.subscribe.success, (state, {id}) => ({
    ...state,
    data:   adapter.updateOne({id, changes: {isUnsubscribed: false}}, state.data),
  })),
  on(fromActions.unsubscribe.success, (state, {id}) => ({
    ...state,
    data:  adapter.updateOne({id, changes: {isUnsubscribed: true}}, state.data),
  })),

);

export function reducer(state: State | undefined, action: Action) {
  return campaignSubscriptionsReducer(state, action);
}

export const getData = (state) => adapter.getSelectors().selectAll(state.data);

import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

export const subscription = createActionGroup({
	source: 'NEWSLETTER2 PREVIEW - SUBSCRIPTION',
	events: {
		openConfirmDialog: props<{campaign: any, isSubscribed: boolean}>(),
		isSubscribedRequest: props<{campaignId: number}>(),
		isSubscribedSuccess: props<{isSubscribed: boolean}>(),
		isSubscribedFailure: props<{error: any}>(),
		subscribeRequest: props<{campaignId: number}>(),
		subscribeSuccess: emptyProps(),
		subscribeFailure: props<{error: any}>(),
		unsubscribeRequest: props<{campaignId: number}>(),
		unsubscribeSuccess: emptyProps(),
		unsubscribeFailure: props<{error: any}>(),
	},
});

export const clear = createAction( 'NEWSLETTER2 PREVIEW - CLEAR' );
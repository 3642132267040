import { CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BlockTypes, SurveyBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import { Store } from '@ngrx/store';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ANSWERTYPE } from '../manage feedback-section-dialog/manage feedback-section-dialog.component';

export interface SelectSurveyDialogData {
  id: number;
  blockType: BlockTypes;
  settings: SurveyBlockProperties;
}

@Component({
  selector: 'rh-select-survey-dialog',
  templateUrl: './select-survey-dialog.component.html',
  styleUrls: ['./select-survey-dialog.component.scss']
})
export class SelectSurveyDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  answerType = ANSWERTYPE;
  options: any[] = [
    {
      value: 1,
      name: 'Thumbs up/down',
    },
    {
      value: 2,
      name: 'Five star rating',
    },
    {
      value: 3,
      name: 'Define your answer',
    },
  ];
  
  previewRadio = new FormControl();

  surveyForm: FormGroup = new FormGroup({
    surveyType: new FormControl(1, [Validators.required]),
    question: new FormControl(null, [Validators.required]),
    answers: new FormArray([new FormControl(null)], []),
  });

  get newsArray() {
    return (this.surveyForm.controls.news as FormArray);
  }
  
  private lastType = null;

  constructor(
    private dialogRef: RdsDialogRef<SelectSurveyDialogComponent>,
    @Inject(RDS_DIALOG_DATA) public data: SelectSurveyDialogData,
    private cdr: ChangeDetectorRef,
    private store$: Store<any>
  ) { 
    this.surveyForm.patchValue({...data.settings}, {emitEvent: false});
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.sink = this.surveyForm
      .get('surveyType')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {

        this.lastType = value;
        switch (value) {
          case ANSWERTYPE.STARS:
          case ANSWERTYPE.THUMBS:
            this.surveyForm.controls.answers.clearValidators();
            this.surveyForm.controls.answers.value.map((a, index) => (this.surveyForm.controls.answers as FormArray).at(index).clearValidators());
            this.surveyForm.controls.question.setValidators([Validators.required]);
            this.surveyForm.controls.question.setErrors(null);
            this.surveyForm.controls.question.markAsUntouched();
            break;
          case ANSWERTYPE.CUSTOM: {
            this.surveyForm.controls.question.setValidators([Validators.required]);
            this.surveyForm.controls.question.setErrors(null);
            this.surveyForm.controls.question.markAsUntouched();
            this.surveyForm.controls.answers.setValidators([CustomValidators.maxLengthList(10), CustomValidators.minLengthList(2)]);
            this.surveyForm.controls.answers.value.map((a, index) => (this.surveyForm.controls.answers as FormArray).at(index).setValidators([Validators.required]));
            break;
          }
        }
        this.surveyForm.controls.question.updateValueAndValidity();
        this.surveyForm.controls.answers.updateValueAndValidity();
        this.surveyForm.controls.answers.value.map((a, index) => (this.surveyForm.controls.answers as FormArray).at(index).updateValueAndValidity());
        this.cdr.detectChanges();
      });
  }

  addNewAnswer() {
    const answers = this.surveyForm.get('answers') as FormArray;
    answers.push(new FormControl('', [Validators.required]));
  }

  removeAnswer(index) {
    const answers = this.surveyForm.get('answers') as FormArray;
    answers.removeAt(index);
  }

  dropAnswer(event: CdkDragSortEvent) {
    console.log(event);
    const formArray = this.surveyForm.get('answers') as FormArray;
    moveItemInArray(
      formArray.controls,
      event.previousIndex,
      event.currentIndex
    );
  }
  
  submit() {
    this.dialogRef.close({
      ...this.data,
      settings: this.surveyForm.value,
    });
  }

}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ImageElementComponent } from '@app/newsletter-new/components/newsletter-form/blocks/image-upload/image-element/image-element.component';
import { NewsletterPreviewWithSectionsComponent } from '@app/newsletter/components/preview-with-sections/newsletter-preview-with-sections.component';
import { NewsletterPreviewWithoutSectionsComponent } from '@app/newsletter/components/preview-without-sections/newsletter-preview-without-sections.component';
import { NewsletterPreviewContainer } from '@app/newsletter/containers/form-preview/newsletter-preview.container';
import { NewsletterPreviewReadOnlyComponent } from '@app/newsletter/inline-editor/newsletter-preview-read-only/newsletter-preview-read-only.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { TranslateModule } from '@ngx-translate/core';
import { ChannelCardItemComponent } from '@shared/2.0/tiles/channel-card-item/channel-card-item.component';
import { ChannelListItemComponent } from '@shared/2.0/tiles/channel-list-item/channel-list-item.component';
import { EventListItemComponent } from '@shared/2.0/tiles/event-list-item/event-list-item.component';
import { ButtonWithDropdownComponent } from '@shared/button-with-dropdown/button-with-dropdown.component';
import { NewsDetailsLoaderComponent } from '@shared/components-loader/news-details-loader/news-details-loader.component';
import { RadaPickerComponent } from '@shared/custom-controls/rada-picker/rada-picker.component';
import { RecipientsPickerComponent } from '@shared/custom-controls/recipients-picker/recipients-picker.component';
import { ImportDialogRecipientsComponent } from '@shared/dialogs/import-dialog-recipients/import-dialog-recipients.component';
import { RadaPreviewDialogComponent } from '@shared/dialogs/previews/rada-preview-dialog/rada-preview-dialog.component';
import { SkeletonDirective } from '@shared/dialogs/save-template-dialog/skeleton.directive';
import { TableWithEditComponent } from '@shared/table-with-edit/table-with-edit.component';
import { CountdownModule } from 'ngx-countdown';
import { AlertComponent } from './2.0/alert/alert.component';
import { CardSwiperComponent } from './2.0/card-swiper/card-swiper.component';
import { CardComponent } from './2.0/card/card.component';
import { HeroCardSwiperMobileComponent } from './2.0/hero-card-swiper-mobile/hero-card-swiper-mobile.component';
import { HeroCardSwiperTabletComponent } from './2.0/hero-card-swiper-tablet/hero-card-swiper-tablet.component';
import { HeroCardSwiperComponent } from './2.0/hero-card-swiper/hero-card-swiper.component';
import { ListComponent } from './2.0/list/list.component';
import { ConfirmLeaveDialogComponent } from './2.0/modals/confirm-leave-dialog/confirm-leave-dialog.component';
import { DeleteEventsDialogComponent } from './2.0/modals/delete-events-dialog/delete-events-dialog.component';
import { PublishEventDialogComponent } from './2.0/modals/publish-event-dialog/publish-event-dialog.component';
import { StreamingMediaDialogComponent } from './2.0/modals/streaming-media-dialog/streaming-media-dialog.component';
import { ContentCardItemComponent } from './2.0/tiles/content-card-item/content-card-item.component';
import { ContentListItemComponent } from './2.0/tiles/content-list-item/content-list-item.component';
import { EventCardItemComponent } from './2.0/tiles/event-card-item/event-card-item.component';
import { EventHeaderComponent } from './2.0/tiles/event-card-item/event-header/event-header.component';
import { HeroNewsCardSwiperComponent } from './2.0/tiles/hero-news-card-swiper/hero-news-card-swiper.component';
import { NewsCardItemComponent } from './2.0/tiles/news-card-item/news-card-item.component';
import { NewsCardSwiperComponent } from './2.0/tiles/news-card-swiper/news-card-swiper.component';
import { NewsListItemComponent } from './2.0/tiles/news-list-item/news-list-item.component';
import { PeopleCardItemComponent } from './2.0/tiles/people-card-item/people-card-item.component';
import { PeopleListItemComponent } from './2.0/tiles/people-list-item/people-list-item.component';
import { TemplateCardComponent } from './2.0/tiles/template-card/template-card.component';
import { TileComponent } from './2.0/tiles/tile/tile.component';
import { AddNewsButtonComponent } from './add-news-menu/add-news-button.component';
import { BackButtonSetDirective } from './back-button/back-button-set.directive';
import { BackButtonComponent } from './back-button/back-button.component';
import { CalendarLabelComponent } from './calendar-label/calendar-label.component';
import { CatalogStatusComponent } from './catalog-status/catalog-status.component';
import { ColorComponent } from './color-picker/color/color.component';
import { NewsletterColorPickerContainer } from './color-picker/newsletter-color-picker.container';
import { ComponentsLoaderService } from './components-loader/components-loader.service';
import { ContentCardLoaderComponent } from './components-loader/content-card-loader/content-card-loader.component';
import { EventCardLoaderComponent } from './components-loader/event-card-loader/event-card-loader.component';
import { EventListLoaderComponent } from './components-loader/event-list-loader/event-list-loader.component';
import { HeroCardLoaderComponent } from './components-loader/hero-card-loader/hero-card-loader.component';
import { HomeEventListLoaderComponent } from './components-loader/home-event-list-loader/home-event-list-loader.component';
import { LabelLoaderComponent } from './components-loader/label-loader/label-loader.component';
import { NewsCardLoaderComponent } from './components-loader/news-card-loader/news-card-loader.component';
import { NewsContentListLoaderComponent } from './components-loader/news-content-list-loader/news-content-list-loader.component';
import { PeopleCardLoaderComponent } from './components-loader/people-card-loader/people-card-loader.component';
import { PeopleListLoaderComponent } from './components-loader/people-list-loader/people-list-loader.component';
import { RecentlyEditedLoaderComponent } from './components-loader/recently-edited-loader/recently-edited-loader.component';
import { RectLoaderComponent } from './components-loader/rect-loader/rect-loader.component';
import { CreateContentButtonComponent } from './create-content-button/create-content-button.component';
import { ChannelPickerComponent } from './custom-controls/channel-picker/channel-picker.component';
import { DatepickerRangeActionsComponent } from './custom-controls/datepicker-range-actions/datepicker-range-actions.component';
import { DropdownKeyPipe } from './custom-controls/dropdown/dropdown-key.pipe';
import { DropdownNodeComponent } from './custom-controls/dropdown/dropdown-node/dropdown-node.component';
import { DropdownComponent } from './custom-controls/dropdown/dropdown.component';
import { EditorComponent } from './custom-controls/editor/editor.component';
import { EmailPickerComponent } from './custom-controls/email-picker/email-picker.component';
import { EntityPickerComponent } from './custom-controls/entity-picker/entity-picker.component';
import { EventPickerComponent } from './custom-controls/event-picker/event-picker.component';
import { PeoplePickerComponent } from './custom-controls/people-picker/people-picker.component';
import { MergeUsersPipe } from './custom-controls/people-role-picker/merge-users.pipe';
import { PeopleRolePickerComponent } from './custom-controls/people-role-picker/people-role-picker.component';
import { RecipientListFilterPipe } from './custom-controls/recipient-list-picker/recipient-list-filter.pipe';
import { RecipientListPickerComponent } from './custom-controls/recipient-list-picker/recipient-list-picker.component';
import { RecipientsPickerTableComponent } from './custom-controls/recipients-picker-table/recipients-picker-table.component';
import { RelatedNewsComponent } from './custom-controls/related-news/related-news.component';
import { SubscriptWrapperComponent } from './custom-controls/subscript-wrapper/subscript-wrapper.component';
import { TopicPickerComponent } from './custom-controls/topic-picker/topic-picker.component';
import { UploadComponent } from './custom-controls/upload/upload.component';
import { UploadBoxComponent } from './custom-controls/upload-box/upload-box.component';
import { TimeAgoPipe } from './dates/pipes/time-ago.pipe';
import { DevelopmentHelpersComponent } from './development-helpers/development-helpers.component';
import { RolesHelperComponent } from './development-helpers/roles/roles-helper/roles-helper.component';
import { SitemapHelperComponent } from './development-helpers/sitemap/sitemap-helper/sitemap-helper.component';
import { SitemapStatusToggleComponent } from './development-helpers/sitemap/sitemap-status-toggle/sitemap-status-toggle.component';
import { AssignSuggestChannelsDialogComponent } from './dialogs/assign-suggest-channels-dialog/assign-suggest-channels-dialog.component';
import { ConfirmAlertDialogContainer } from './dialogs/confirm-alert-dialog/confirm-alert-dialog.container';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { CopyToClipboardDialogContainer } from './dialogs/copy-to-clipboard-dialog/copy-to-clipboard-dialog.container';
import { CropImageDialogComponent } from './dialogs/crop-image-dialog/crop-image-dialog.component';
import { IntranetCoordinatorPreviewDialogComponent } from './dialogs/intranet-coordinator-preview-dialog/intranet-coordinator-preview-dialog.component';
import { GoogleLoginDialogContainer } from './dialogs/overlay-dialogs/google-login-dialog/google-login-dialog.container';
import { GoogleOneTapDialogContainer } from './dialogs/overlay-dialogs/google-one-tap-dialog/google-one-tap-dialog.container';
import { HelloDialogContainer } from './dialogs/overlay-dialogs/hello-dialog/hello-dialog.container';
import { UserNotCreatedDialogContainer } from './dialogs/overlay-dialogs/user-not-created-dialog/user-not-created-dialog.container';
import { AlertPreviewDialogComponent } from './dialogs/previews/alert-preview-dialog/alert-preview-dialog.component';
import { AlertRecievedDialogComponent } from './dialogs/previews/alert-recieved-dialog/alert-recieved-dialog.component';
import { EventPreviewDialogComponent } from './dialogs/previews/event-preview-dialog/event-preview-dialog.component';
import { EventPreviewComponent } from './dialogs/previews/event-preview-dialog/event-preview/event-preview.component';
import { NewsContentPreviewComponent } from './dialogs/previews/news-preview-dialog/news-content-preview.component';
import { NewsPreviewDialogComponent } from './dialogs/previews/news-preview-dialog/news-preview-dialog.component';
import { TickerPreviewDialogComponent } from './dialogs/previews/ticker-preview-dialog/ticker-preview-dialog.component';
import { RejectionCommentDialogComponent } from './dialogs/rejection-comment-dialog/rejection-comment-dialog.component';
import { RelatedNewsDialogComponent } from './dialogs/related-news-dialog/related-news-dialog.component';
import { RenameDialogComponent } from './dialogs/rename-dialog/rename-dialog.component';
import { SaveTemplateDialogComponent } from './dialogs/save-template-dialog/save-template-dialog.component';
import { SelectDialogComponent } from './dialogs/select-dialog/select-dialog.component';
import { SendCopyDialogComponent } from './dialogs/send-copy-dialog/send-copy-dialog.component';
import { ShareTemplateDialogComponent } from './dialogs/share-template-dialog/share-template-dialog.component';
import { AdvancedFiltersComponent } from './filters/advanced-filters/advanced-filters.component';
import { TableFiltersComponent } from './filters/table-filters/table-filters.component';
import { FlatTreeComponent } from './flat-tree/flat-tree.component';
import { FormStatusBadgeComponent } from './form-status-badge/form-status-badge.component';
import { FormStepContentComponent } from './form-step-content/form-step-content.component';
import { IsSameObjectPipe } from './is-same-object/is-same-object.pipe';
import { LoaderComponent } from './loader/loader.component';
import { CommentInputComponent } from './news-details/comment-input/comment-input.component';
import { DisableCommentingDialogComponent } from './news-details/news-comments/disable-commenting-dialog/disable-commenting-dialog.component';
import { EnableCommentingDialogComponent } from './news-details/news-comments/enable-commenting-dialog/enable-commenting-dialog.component';
import { NewsCommentsComponent } from './news-details/news-comments/news-comments.component';
import { NewsContentComponent } from './news-details/news-content/news-content.component';
import { NewsFooterComponent } from './news-details/news-footer/news-footer.component';
import { NewsHeaderComponent } from './news-details/news-header/news-header.component';
import { NewsRelatedComponent } from './news-details/news-related/news-related.component';
import { OembedPipe } from './oembed/oembed.pipe';
import { RdsModule } from './rds.module';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SingleCatalogItemComponent } from './single-catalog-item/single-catalog-item.component';
import { TableWithEditPerformanceComponent } from './table-with-edit-performance/table-with-edit-performance.component';
import { TableComponent } from './table/table.component';
import { SingleTickerComponent } from './tickers/ticker/ticker.component';
import { TickersComponent } from './tickers/tickers/tickers.component';
import { UserGroupComponent } from './user-group/user-group.component';
import { UserSingleComponent } from './user-single/user-single.component';
import { AttentionRequiredPipe } from './utils/attention-required.pipe';
import { AutocompletesPipe } from './utils/autocomplete.pipe';
import { AutofocusDirective } from './utils/autofocus.directive';
import { ChannelNamePipe } from './utils/channel-name.pipe';
import { ClickOutsideDirective } from './utils/clickOutside.directive';
import { ColumnNamePipe } from './utils/column-text.pipe';
import { ConvertSizePipe } from './utils/convert-size.pipe';
import { CountTextPipe } from './utils/count-text';
import { DictionaryGetPipe } from './utils/dictionary-get.pipe';
import { DragDropDirective } from './utils/drag-drop.directive';
import { DynamicMenuDirective } from './utils/dynamicMenu.directive';
import { EventsForRangePipe } from './utils/events-for-range.pipe';
import { FetchAvatarDirective } from './utils/fetchAvatar.directive';
import { FormStoreSyncDirective } from './utils/form-store-sync.directive';
import { GcsMatchHighlightPipe } from './utils/gcs-match-highlight.pipe';
import { HasRoleDirective } from './utils/has-role.directive';
import { HasRolePipe } from './utils/has-role.pipe';
import { HighlightPipe } from './utils/highlight.pipe';
import { IfEnvironmentDirective } from './utils/if-environment';
import { IfViewModeDirective } from './utils/if-view-mode.directive';
import { InitialsPipe } from './utils/initials.pipe';
import { InsertLinksPipe } from './utils/insert-links.pipe';
import { IsViewModePipe } from './utils/is-view-mode.pipe';
import { IsOVerflowingDirective } from './utils/isOverflowing.directive';
import { KeydownEscDirective } from './utils/keydownEsc.directive';
import { LanguageNamePipe } from './utils/language-name.pipe';
import { ListFromArrayPipe } from './utils/list-from-array.pipe';
import { MatchViewModePipe } from './utils/match-view-mode.pipe';
import { MaxPipe } from './utils/max.pipe';
import { MinPipe } from './utils/min.pipe';
import { NewsForChannelPipe } from './utils/news-for-channel.pipe';
import { NewsTitlePipe } from './utils/news-title.pipe';
import { NumToArrayPipe } from './utils/numToArray.pipe';
import { KeysPipe } from './utils/object-keys.pipe';
import { OverflowingContentDirective } from './utils/overflowing-content.directive';
import { OverrideSelectLabelDirective } from './utils/override-select-label.directive';
import { RequireAsteriskDirective } from './utils/require-asterisk.directive';
import { ShouldTruncateDirective } from './utils/should-truncate.directive';
import { SingleEventForRangePipe } from './utils/single-events-for-range.pipe';
import { SingleNewsForChannelPipe } from './utils/single-news-for-channel.pipe';
import { TaxonomyNamePipe } from './utils/taxonomy-name.pipe';
import { TruncateWithLinkPipe } from './utils/truncate-with-link.pipe';
import { TruncatePipe } from './utils/truncate.pipe';
import { UrlPipe } from './utils/url.pipe';
import { UserListPipe } from './utils/user-list.pipe';
import { ViewBarComponent } from './view-bar/view-bar.component';
import { GetLinkPipe } from './utils/get-link.pipe';
import { NumberSelectComponent } from './custom-controls/number-select/number-select.component';
import { NewsAbstractForLanguagePipe } from './utils/news-abstract-for-language';
import { FilterArrayPipe } from './utils/filter-array.pipe';
import { ManageRoleDialogComponent } from '@shared/dialogs/manage-role-dialog/manage-role-dialog.component';
import { CroppComponent } from './cropp/cropp.component';
import { SchedulerComponent } from '@shared/scheduler/scheduler.component';
import { GetSesTagsPipe } from './utils/get-ses-tags';
import { DecodePipe } from './utils/decode-base64';
import { DayFormatDirective, MonthFormatDirective, YearFormatDirective } from './utils/date-format';
import { RadaAutocompleteComponent } from './custom-controls/autocompletes/rada-autocomplete/rada-autocomplete.component';
import { AutocompleteComponent } from './custom-controls/autocomplete/autocomplete.component';
import { RadaGroupAutocompleteItemComponent } from './custom-controls/autocomplete/templates/item/rada-group-autocomplete-item/rada-group-autocomplete-item.component';
import { UserAutocompleteItemComponent } from './custom-controls/autocomplete/templates/item/user-autocomplete-item/user-autocomplete-item.component';
import { UserAutocompleteSelectedComponent } from './custom-controls/autocomplete/templates/selected/user-autocomplete-selected/user-autocomplete-selected.component';
import { EventAutocompleteItemComponent } from './custom-controls/autocomplete/templates/item/event-autocomplete-item/event-autocomplete-item.component';
import { EventAutocompleteSelectedComponent } from './custom-controls/autocomplete/templates/selected/event-autocomplete-selected/event-autocomplete-selected.component';
import { GetSesSurveyTagsPipe } from './utils/get-ses-survey-tags';

const EXTERNAL_CONTAINERS = [
	NewsletterPreviewContainer,
	NewsletterPreviewWithoutSectionsComponent,
	NewsletterPreviewWithSectionsComponent,
	NewsletterPreviewReadOnlyComponent,
];

const LEGACY_COMPONENTS = [
	NewsDetailsLoaderComponent,
	NewsCardLoaderComponent,
	HeroCardLoaderComponent,
	PeopleCardLoaderComponent,
	PeopleListLoaderComponent,
	EventListLoaderComponent,
	ContentCardLoaderComponent,
	NewsContentListLoaderComponent,
	HomeEventListLoaderComponent,
	EventCardLoaderComponent,
	EventListItemComponent,
	LabelLoaderComponent,
	RectLoaderComponent,
	RecentlyEditedLoaderComponent,
	CalendarLabelComponent,
	LoaderComponent,
	TickersComponent,
	SingleTickerComponent,
	CardSwiperComponent,
	HeroCardSwiperComponent,
	HeroCardSwiperMobileComponent,
	HeroCardSwiperTabletComponent,
	ListComponent,
	NewsCardItemComponent,
	ChannelCardItemComponent,
	NewsListItemComponent,
	ContentCardItemComponent,
	ContentListItemComponent,
	PeopleCardItemComponent,
	PeopleListItemComponent,
	ChannelListItemComponent,
	NewsCardSwiperComponent,
	HeroNewsCardSwiperComponent,
];

const RH_COMPONENTS = [
	TemplateCardComponent,
	TileComponent,
	AddNewsButtonComponent,
	BackButtonComponent,
	ChannelPickerComponent,
	DropdownComponent,
	DropdownNodeComponent,
	EditorComponent,
	StreamingMediaDialogComponent,
	EntityPickerComponent,
	PeoplePickerComponent,
	RecipientsPickerTableComponent,
	PeopleRolePickerComponent,
	RelatedNewsComponent,
	SubscriptWrapperComponent,
	TopicPickerComponent,
	RadaPickerComponent,
	RecipientsPickerComponent,
	EventPickerComponent,
	UploadComponent,
	UploadBoxComponent,
	AssignSuggestChannelsDialogComponent,
	CropImageDialogComponent,
	RadaPreviewDialogComponent,
	AlertPreviewDialogComponent,
	NewsContentPreviewComponent,
	NewsPreviewDialogComponent,
	TickerPreviewDialogComponent,
	SelectDialogComponent,
	ImportDialogRecipientsComponent,
	FormStatusBadgeComponent,
	FormStepContentComponent,
	TableComponent,
	TableWithEditComponent,
	TableWithEditPerformanceComponent,
	TableFiltersComponent,
	AdvancedFiltersComponent,
	UserGroupComponent,
	UserSingleComponent,
	ViewBarComponent,
	NewsRelatedComponent,
	NewsHeaderComponent,
	NewsFooterComponent,
	NewsContentComponent,
	NewsCommentsComponent,
	EventPreviewDialogComponent,
	EventPreviewComponent,
	CommentInputComponent,
	EventCardItemComponent,
	EventHeaderComponent,
	CardComponent,
	NewsCardItemComponent,
	SitemapHelperComponent,
	SitemapStatusToggleComponent,
	FlatTreeComponent,
	RolesHelperComponent,
	DevelopmentHelpersComponent,
	IntranetCoordinatorPreviewDialogComponent,
	EmailPickerComponent,
	ConfirmAlertDialogContainer,
	AlertRecievedDialogComponent,
	CopyToClipboardDialogContainer,
	HelloDialogContainer,
	GoogleOneTapDialogContainer,
	GoogleLoginDialogContainer,
	UserNotCreatedDialogContainer,
	ColorComponent,
	RejectionCommentDialogComponent,
	DeleteEventsDialogComponent,
	PublishEventDialogComponent,
	ConfirmLeaveDialogComponent,
	RelatedNewsDialogComponent,
	AlertComponent,
	NewsletterColorPickerContainer,
	SingleCatalogItemComponent,
	RenameDialogComponent,
	SaveTemplateDialogComponent,
	SendCopyDialogComponent,
	ShareTemplateDialogComponent,
	RelatedNewsComponent,
	ConfirmDialogComponent,
	CatalogStatusComponent,

	TemplateCardComponent,
	TileComponent,
	AddNewsButtonComponent,
	BackButtonComponent,
	ChannelPickerComponent,
	DropdownComponent,
	DropdownNodeComponent,
	EditorComponent,
	StreamingMediaDialogComponent,
	EntityPickerComponent,
	PeoplePickerComponent,
	PeopleRolePickerComponent,
	RelatedNewsComponent,
	SubscriptWrapperComponent,
	TopicPickerComponent,
	UploadComponent,
	AssignSuggestChannelsDialogComponent,
	CropImageDialogComponent,
	AlertPreviewDialogComponent,
	NewsContentPreviewComponent,
	NewsPreviewDialogComponent,
	TickerPreviewDialogComponent,
	SelectDialogComponent,
	FormStatusBadgeComponent,
	FormStepContentComponent,
	TableComponent,
	TableFiltersComponent,
	AdvancedFiltersComponent,
	UserGroupComponent,
	UserSingleComponent,
	ViewBarComponent,
	NewsRelatedComponent,
	NewsHeaderComponent,
	NewsFooterComponent,
	NewsContentComponent,
	NewsCommentsComponent,
	EventPreviewDialogComponent,
	EventPreviewComponent,
	CommentInputComponent,
	EventCardItemComponent,
	EventHeaderComponent,
	CardComponent,
	NewsCardItemComponent,
	SitemapHelperComponent,
	SitemapStatusToggleComponent,
	FlatTreeComponent,
	RolesHelperComponent,
	DevelopmentHelpersComponent,
	IntranetCoordinatorPreviewDialogComponent,
	EmailPickerComponent,
	ConfirmAlertDialogContainer,
	AlertRecievedDialogComponent,
	CopyToClipboardDialogContainer,
	HelloDialogContainer,
	GoogleOneTapDialogContainer,
	GoogleLoginDialogContainer,
	UserNotCreatedDialogContainer,
	ColorComponent,
	RejectionCommentDialogComponent,
	DeleteEventsDialogComponent,
	PublishEventDialogComponent,
	ConfirmLeaveDialogComponent,
	RelatedNewsDialogComponent,
	AlertComponent,
	NewsletterColorPickerContainer,
	SingleCatalogItemComponent,
	RenameDialogComponent,
	SaveTemplateDialogComponent,
	SendCopyDialogComponent,
	ShareTemplateDialogComponent,
	RelatedNewsComponent,
	ConfirmDialogComponent,
	CatalogStatusComponent,
	CreateContentButtonComponent,
	ButtonWithDropdownComponent,
	DisableCommentingDialogComponent,
	EnableCommentingDialogComponent,
	RecipientListPickerComponent,
	ImageElementComponent,
	DatepickerRangeActionsComponent,
	NumberSelectComponent,
	ManageRoleDialogComponent,
	CroppComponent,
	SchedulerComponent,
	RadaAutocompleteComponent,
	AutocompleteComponent,
	RadaGroupAutocompleteItemComponent,
	UserAutocompleteItemComponent,
	UserAutocompleteSelectedComponent,
	EventAutocompleteItemComponent,
	EventAutocompleteSelectedComponent,
];

const DIRECTIVES = [
	FormStoreSyncDirective,
	OverflowingContentDirective,
	ClickOutsideDirective,
	DragDropDirective,
	HasRoleDirective,
	IfViewModeDirective,
	IsOVerflowingDirective,
	KeydownEscDirective,
	ShouldTruncateDirective,
	BackButtonSetDirective,
	RequireAsteriskDirective,
	FetchAvatarDirective,
	IfEnvironmentDirective,
	SkeletonDirective,
	DynamicMenuDirective,
	AutofocusDirective,
	OverrideSelectLabelDirective,
	DayFormatDirective,
	MonthFormatDirective,
	YearFormatDirective,
];

const PIPES = [
	MergeUsersPipe,
	SafeHtmlPipe,
	TimeAgoPipe,
	HighlightPipe,
	DropdownKeyPipe,
	IsSameObjectPipe,
	OembedPipe,
	ChannelNamePipe,
	ConvertSizePipe,
	CountTextPipe,
	GcsMatchHighlightPipe,
	HasRolePipe,
	HighlightPipe,
	InsertLinksPipe,
	IsViewModePipe,
	MatchViewModePipe,
	NewsForChannelPipe,
	SingleNewsForChannelPipe,
	SingleEventForRangePipe,
	NewsTitlePipe,
	NumToArrayPipe,
	MaxPipe,
	MinPipe,
	KeysPipe,
	DictionaryGetPipe,
	TaxonomyNamePipe,
	TruncateWithLinkPipe,
	TruncatePipe,
	UrlPipe,
	InitialsPipe,
	UserListPipe,
	LanguageNamePipe,
	ListFromArrayPipe,
	AttentionRequiredPipe,
	ColumnNamePipe,
	RecipientListFilterPipe,
	EventsForRangePipe,
	AutocompletesPipe,
	GetLinkPipe,
	GetSesTagsPipe,
	GetSesSurveyTagsPipe,
	DecodePipe,
	NewsAbstractForLanguagePipe,
	FilterArrayPipe,
];
const SERVICES = [ComponentsLoaderService];

const MODULES = [
	CommonModule,
	FormsModule,
	HttpClientModule,
	CountdownModule,
	OverlayModule,
	DragDropModule,
	RdsModule,
	ReactiveFormsModule,
	RouterModule,
	TranslateModule,
	ContentLoaderModule,
	CKEditorModule,
];

@NgModule({
	imports: [...MODULES],
	exports: [
		...DIRECTIVES,
		...LEGACY_COMPONENTS,
		...RH_COMPONENTS,
		...MODULES,
		...PIPES,
		NewsletterPreviewContainer,
		NewsletterPreviewReadOnlyComponent,
		NewsletterPreviewWithSectionsComponent,
		NewsletterPreviewWithoutSectionsComponent,
	],
	declarations: [...EXTERNAL_CONTAINERS, ...LEGACY_COMPONENTS, ...RH_COMPONENTS, ...DIRECTIVES, ...PIPES, RadaAutocompleteComponent],
	providers: [...SERVICES, OembedPipe, ConvertSizePipe],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [],
		};
	}
}

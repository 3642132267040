import { createSelector } from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromReducer from '@app/newsletter-new/store/newsletter-preview/newsletter-preview.reducer';
import { DEFAULT_DISCLAIMER, RhNewsletterRequest } from '@app/newsletter-new/models/newsletter';

export const selectNewsletterPreview = createSelector(fromNewsletter.selectState, fromNewsletter.getNewsletterPreview);

export const selectIsSubscribed = createSelector(
	selectNewsletterPreview,
	fromReducer.getIsSubscribed
);
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import * as fromCampaignSubscriptions from '@app/user-settings/store/campaign-subscriptions';
import {select, Store} from '@ngrx/store';
import {SubSink} from 'subsink';
import {Observable} from 'rxjs';
import {CampaignListModel} from '@app/user-settings/store/campaign-subscriptions';
import {tap} from 'rxjs/operators';
import {campaign} from '@app/newsletter-new/store/mock';

@Component({
  selector: 'atr-subscriptions',
  templateUrl: './subscriptions.container.html',
  styleUrls: ['./subscriptions.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsContainer implements OnInit, OnDestroy {
  private subs = new SubSink();

  data$: Observable<Array<CampaignListModel>> = this.store$.pipe(select(fromCampaignSubscriptions.selectData), tap(data => console.log(data)));
  public subscribed: CampaignListModel[];
  public unSubscribed: CampaignListModel[];
  ngOnInit() {
    this.store$.dispatch(fromCampaignSubscriptions.list.request());
    this.subs.sink = this.data$.subscribe(data => {
      this.subscribed = data.filter(d => !d.isUnsubscribed);
      this.unSubscribed = data.filter(d => d.isUnsubscribed);
      this.cdr.markForCheck();
    });
  }

  subscribe(id) {
    this.store$.dispatch(fromCampaignSubscriptions.subscribe.request({id}));
  }
  unsubscribe(id) {
    this.store$.dispatch(fromCampaignSubscriptions.unsubscribe.request({id}));
  }
  ngOnDestroy() {

  }
  constructor(
    private cdr: ChangeDetectorRef,
    private store$: Store<fromCampaignSubscriptions.State>
  ) {
  }

  protected readonly campaign = campaign;
}

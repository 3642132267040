import { Pipe, PipeTransform } from '@angular/core';
import { Base64 } from 'js-base64';
import { TagTypes } from './get-ses-tags';

@Pipe({
	name: 'getSesSurveyTags',
})
export class GetSesSurveyTagsPipe implements PipeTransform {
	transform(question: string, answer: string, order: string | number, index: string): any {
		
		let encodedName = Base64.encodeURI(answer);
		let encodedQuestion = Base64.encodeURI(question);
		return `type:${TagTypes.SURVEY};name:${encodedName};question:${encodedQuestion};order:${order};index:${index};linkId:GUID_GOES_HERE`;
	}

	constructor() {}
}

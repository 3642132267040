
import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {CampaignListModel} from '@app/user-settings/store/campaign-subscriptions/campaign-subscriptions.reducer';


export const list = createActionGroup({
    source: 'SUBSCRIPTIONS LIST',
    events: {
      request: emptyProps(),
      success: props<{ data: CampaignListModel[] }>(),
      failure:   props<{ error: any }>(),
    },
});
export const subscribe = createActionGroup({
  source: 'SUBSCRIPTIONS LIST SUBSCRIBE',
  events: {
    request: props<{ id: number }>(),
    success: props<{ id: number }>(),
    failure:   props<{ error: any }>(),
  },
});export const unsubscribe = createActionGroup({
  source: 'SUBSCRIPTIONS LIST UNSUBSCRIBE',
  events: {
    request: props<{ id: number }>(),
    success: props<{ id: number }>(),
    failure:   props<{ error: any }>(),
  },
});

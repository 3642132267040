import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';

import * as fromActions from './campaign-subscriptions.actions';
import * as fromReducer from './campaign-subscriptions.reducer';
import {  Store } from '@ngrx/store';
import {NewsletterService} from '@core/services/newsletter.service';
import {of} from 'rxjs';

@Injectable()
export class CampaignSubscriptionsEffects {

  public initForm$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.list.request),
    switchMap(() => this.newsletterService.getCampaignSubscriptions().pipe(
      map(({data}) => fromActions.list.success({ data})),
      catchError(({ message }) => of(fromActions.list.failure({ error: message })))
    ))
  ), { dispatch: true }
);

  public subscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribe.request),
      switchMap(({id}) => this.newsletterService.subscribe(id).pipe(
        map(() => fromActions.subscribe.success({ id})),
        catchError(({ message }) => of(fromActions.subscribe.failure({ error: message })))
      ))
    ), { dispatch: true }
  );
  public unsubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.unsubscribe.request),
      switchMap(({id}) => this.newsletterService.unsubscribe(id).pipe(
        map(() => fromActions.unsubscribe.success({ id})),
        catchError(({ message }) => of(fromActions.unsubscribe.failure({ error: message })))
      ))
    ), { dispatch: true }
  );


  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsletterService: NewsletterService,
  ) {}
}

<div class="row py-7">
  <div class="col-8 offset-2 py-4">
    <div class="row" *ngIf="subscribed.length">
      <div class="col">
        <h4 class="ui-heading-4 mb-3">Campaigns Subscribed</h4>

        <ng-container>
          <div class="row mb-4" *ngFor="let campaign of subscribed" >
            <div class="col-6 vertical">
              {{campaign.campaignName || campaign.campaignId}}
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-auto offset-both">
                  <button rds-primary-button [disabled]="!campaign.allowToUnsubscribe"
                          (click)="unsubscribe(campaign.campaignId)" size="m">
                    Unsubscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <rds-divider class="my-5"></rds-divider>
    <div class="row" *ngIf="unSubscribed.length">
      <div class="col">
        <h4 class="ui-heading-4 mb-3">Campaigns Unsubscribed</h4>

        <ng-container>
          <div class="row mb-4" *ngFor="let campaign of unSubscribed" >
            <div class="col-6 vertical">
              {{campaign.campaignName || campaign.campaignId}}
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-auto offset-both">
                  <button rds-primary-button [disabled]="!campaign.allowToSubscribe"
                          (click)="subscribe(campaign.campaignId)" size="m">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>

import { SectionTypes, SubsectionTypes } from './section-type.enum';
import { RhEvent } from '@app/events/models/event';
import { Channel, NewsletterNews } from '@app/core/models';
import { ANSWERTYPE } from '../dialogs/manage feedback-section-dialog/manage feedback-section-dialog.component';

export const SPACING_REGEXP = /margin: ([0-9]*px) 0;/gm;
export const NEWS_ID_REGEXP = /data-newsid='(.*?)'/gm;
export const NEWS_TITLE_REGEXP = /<h3.*>\s*(.*?)\s*<\/h3>/gm;
export const BUTTON_TYPE_REGEXP = /data-buttontype='(.*?)'/gm;
export const IMAGE_SRC_REGEXP = /src='(.*?)'/gm;

export enum BlockTypes {
	TEXT = 'TEXT',
	DIVIDER = 'DIVIDER',
	SPACER = 'SPACER',
	IMAGE = 'IMAGE',
	BUTTON_LINK = 'BUTTON_LINK',
	NEWS = 'NEWS',
	EVENT = 'EVENT',
	CHANNEL = 'CHANNEL',
	SURVEY = 'SURVEY',
	ANCHOR = 'ANCHOR',
}

export const BlockDescriptions = {
	[BlockTypes.TEXT]: '<p style="white-space: nowrap;">Displays customizable text content in your email.</p>',
	[BlockTypes.DIVIDER]: '<p style="white-space: nowrap;">Inserts a horizontal line to visually separate content.</p>',
	[BlockTypes.SPACER]: '<p style="white-space: nowrap;">Adds adjustable space between sections for better layout.</p>',
	[BlockTypes.IMAGE]: '<p style="white-space: nowrap;">Displays an image with an optional link.</p>',
	[BlockTypes.BUTTON_LINK]: '<p style="white-space: nowrap;">Adds a clickable button linked to a custom URL.</p>',
	[BlockTypes.NEWS]: '<p style="white-space: nowrap;">Displays abstract of single news article</p>',
	[BlockTypes.EVENT]: '<p style="white-space: nowrap;">Displays abstract of single event</p>',
	[BlockTypes.CHANNEL]: '<p style="white-space: nowrap;">Displays multiple news from single channel</p>',
	[BlockTypes.SURVEY]: '<p style="white-space: nowrap;">Displays a survey question.</p>',
	[BlockTypes.ANCHOR]: '<p style="white-space: nowrap;">Creates a target link for navigation within the email.</p><br/><p style="white-space: normal;">IMPORTANT!<br/>Ensure anchors are spaced apart — if displayed too close together, the scrolling feature may not work correctly, making it seem broken.</p>',
}

export type BlockProperties =
	| AnchorBlockProperties
	| TableOfContentBlockProperties
	| ChannelBlockProperties
	| EventBlockProperties
	| ImageBlockProperties
	| ButtonLinkBlockProperties
	| DividerBlockProperties
	| NewsBlockProperties
	| SpacerBlockProperties
	| SurveyBlockProperties
	| EmptyBlockProperties;

export interface SpacingModel {
	value: string;
	label: string;
}

export const SPACING_VALUES: Array<SpacingModel> = [
	{
		value: '16px',
		label: '16 px',
	},
	{
		value: '24px',
		label: '24 px',
	},
	{
		value: '32px',
		label: '32 px',
	},
];

export const BLOCK_LIST: Array<BlockTypes> = [
	BlockTypes.TEXT,
	BlockTypes.SPACER,
	BlockTypes.DIVIDER,
	BlockTypes.IMAGE,
	BlockTypes.NEWS,
	BlockTypes.EVENT,
	BlockTypes.CHANNEL,
	BlockTypes.BUTTON_LINK,
	BlockTypes.SURVEY,
	BlockTypes.ANCHOR,
];

export type BLOCK_SETTING_TYPE = {
	[BlockTypes.TEXT]: EmptyBlockProperties;
	[BlockTypes.DIVIDER]: DividerBlockProperties;
	[BlockTypes.SPACER]: SpacerBlockProperties;
	[BlockTypes.IMAGE]: EmptyBlockProperties;
	[BlockTypes.BUTTON_LINK]: ButtonLinkBlockProperties;
	[BlockTypes.NEWS]: NewsBlockProperties;
	[BlockTypes.EVENT]: EmptyBlockProperties;
	[BlockTypes.CHANNEL]: EmptyBlockProperties;
	[BlockTypes.SURVEY]: SurveyBlockProperties;
	[BlockTypes.ANCHOR]: EmptyBlockProperties;
};

export const DEFAULT_BLOCK_SETTINGS = {
	[BlockTypes.TEXT]: {} as EmptyBlockProperties,
	[BlockTypes.EVENT]: {
		card: false,
		end: null,
		event: null,
		flipped: false,
		start: null,
	} as EventBlockProperties,
	[BlockTypes.DIVIDER]: {
		spacing: '16px 0',
	} as DividerBlockProperties,
	[BlockTypes.SPACER]: {
		spacing: '16px 0',
	} as SpacerBlockProperties,
	[BlockTypes.IMAGE]: {
		link: '',
		photoUrl: null,
	} as ImageBlockProperties,
	[BlockTypes.BUTTON_LINK]: {
		link: '',
		position: 'left',
		text: '',
		type: 'primary',
	} as ButtonLinkBlockProperties,
	[BlockTypes.NEWS]: {
		card: false,
		news: null,
		flipped: false,
	} as NewsBlockProperties,
	[BlockTypes.CHANNEL]: {
		card: false,
		selectedNews: [],
		channel: null,
		selectionType: 'recent',
	} as ChannelBlockProperties,
	[BlockTypes.SURVEY]: {} as SurveyBlockProperties,
	[BlockTypes.ANCHOR]: {
		label: '',
		distanceToNext: null,
		distanceToPrev: null,
	} as AnchorBlockProperties,
};

export interface AnchorBlockProperties {
	label: string;
	distanceToPrev: number;
	distanceToNext: number;
}

export interface TableOfContentBlockProperties {
	title: string;
}

export interface NewsBlockProperties {
	news: NewsletterNews;
	newsLanguage: string;
	flipped: boolean;
	card: boolean;
}

export interface EventBlockProperties {
	event: Partial<RhEvent>;
	flipped: boolean;
	card: boolean;
	start: number;
	end: number;
}

export interface ChannelBlockProperties {
	channel: Channel;
	selectionType: 'recent' | 'manual';
	selectedNews: Array<{
		news: NewsletterNews;
		language: string;
	}>;
	addLink: boolean;
	linkText: string;
	card: boolean;
}

export interface DividerBlockProperties {
	spacing: string;
}

export interface SpacerBlockProperties {
	spacing: string;
}

export interface EmptyBlockProperties {}

export interface ButtonLinkBlockProperties {
	position: string;
	link: string;
	text: string;
	type: string;
}

export interface ImageBlockProperties {
	photoUrl: string;
	photoName: string;
	link: string;
}

export interface SurveyBlockProperties {
	surveyType: ANSWERTYPE;
	surveyOrder?: number;
	answers: Array<string>;
	question: string;
}

export interface Block<T = BlockProperties> {
	id: string;
	type: BlockTypes;
	markup?: string;
	settings: T;
}

/* tslint:disable */
export const BLOCK_MARKUPS = {
	[BlockTypes.TEXT]: ``,
	[BlockTypes.SPACER]: ``,
	[BlockTypes.DIVIDER]: ``,
	[BlockTypes.IMAGE]: ``,
	[BlockTypes.BUTTON_LINK]: ``,
	[BlockTypes.NEWS]: ``,
};
/* tslint:disable */

export const BLOCK_ALLOWED_SECTIONS = {
	[BlockTypes.TEXT]: [
		SectionTypes.SINGLE,
		SectionTypes.ONE_IN_THREE,
		SectionTypes.THREE_IN_ONE,
		SectionTypes.TWO_EQUAL,
		SectionTypes.THREE_EQUAL,
	],
	[BlockTypes.SPACER]: [
		SectionTypes.SINGLE,
		SectionTypes.ONE_IN_THREE,
		SectionTypes.THREE_IN_ONE,
		SectionTypes.TWO_EQUAL,
		SectionTypes.THREE_EQUAL,
	],
	[BlockTypes.DIVIDER]: [
		SectionTypes.SINGLE,
		SectionTypes.ONE_IN_THREE,
		SectionTypes.THREE_IN_ONE,
		SectionTypes.TWO_EQUAL,
		SectionTypes.THREE_EQUAL,
	],
	[BlockTypes.IMAGE]: [
		SectionTypes.SINGLE,
		SectionTypes.ONE_IN_THREE,
		SectionTypes.THREE_IN_ONE,
		SectionTypes.TWO_EQUAL,
		SectionTypes.THREE_EQUAL,
	],
	[BlockTypes.BUTTON_LINK]: [
		SectionTypes.SINGLE,
		SectionTypes.ONE_IN_THREE,
		SectionTypes.THREE_IN_ONE,
		SectionTypes.TWO_EQUAL,
		SectionTypes.THREE_EQUAL,
	],
	[BlockTypes.NEWS]: [SectionTypes.SINGLE, SectionTypes.TWO_EQUAL],
	[BlockTypes.EVENT]: [SectionTypes.SINGLE, SectionTypes.TWO_EQUAL],
	[BlockTypes.CHANNEL]: [SectionTypes.SINGLE],
	[BlockTypes.SURVEY]: [SectionTypes.SINGLE],
	[BlockTypes.ANCHOR]: [SectionTypes.SINGLE],
};

export const BLOCK_ALLOWED_SUBSECTIONS = {
	[BlockTypes.TEXT]: [SubsectionTypes.PERCENT_100, SubsectionTypes.PERCENT_66, SubsectionTypes.PERCENT_50],
	[BlockTypes.SPACER]: [
		SubsectionTypes.PERCENT_100,
		SubsectionTypes.PERCENT_66,
		SubsectionTypes.PERCENT_50,
		SubsectionTypes.PERCENT_33,
	],
	[BlockTypes.DIVIDER]: [
		SubsectionTypes.PERCENT_100,
		SubsectionTypes.PERCENT_66,
		SubsectionTypes.PERCENT_50,
		SubsectionTypes.PERCENT_33,
	],
	[BlockTypes.IMAGE]: [
		SubsectionTypes.PERCENT_100,
		SubsectionTypes.PERCENT_66,
		SubsectionTypes.PERCENT_50,
		SubsectionTypes.PERCENT_33,
	],
	[BlockTypes.BUTTON_LINK]: [
		SubsectionTypes.PERCENT_100,
		SubsectionTypes.PERCENT_66,
		SubsectionTypes.PERCENT_50,
		SubsectionTypes.PERCENT_33,
	],
	[BlockTypes.NEWS]: [SubsectionTypes.PERCENT_100, SubsectionTypes.PERCENT_50],
	[BlockTypes.EVENT]: [SubsectionTypes.PERCENT_100, SubsectionTypes.PERCENT_50],
	[BlockTypes.CHANNEL]: [SubsectionTypes.PERCENT_100],
	[BlockTypes.SURVEY]: [SubsectionTypes.PERCENT_100],
	[BlockTypes.ANCHOR]: [SubsectionTypes.PERCENT_100],
};

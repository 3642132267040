import { RhEvent } from '@app/events/models/event';
import { Block, BlockProperties, BlockTypes } from '@app/newsletter-new/models/block-type.enum';
import { Section, SectionBackground, SectionTypes } from '@app/newsletter-new/models/section-type.enum';
import { createAction, props } from '@ngrx/store';

export const loadNewsletterSections = createAction('[NEWSLETTER BLOCKS] Load newsletter sections', props<{ sections: Array<Section> }>());

export const addSection = createAction('[NEWSLETTER BLOCKS] Add section', props<{ section: Section }>());

export const toggleBorders = createAction('[NEWSLETTER BLOCKS] Toggle borders');

export const hoverBlockType = createAction('[NEWSLETTER BLOCKS] Hover block drag', props<{ blockType: BlockTypes }>());

export const unhoverBlockType = createAction('[NEWSLETTER BLOCKS] Unhover block drag');

export const startBlockDrag = createAction('[NEWSLETTER BLOCKS] Start block drag', props<{ blockType: BlockTypes }>());

export const endBlockDrag = createAction('[NEWSLETTER BLOCKS] End block drag');

export const createSection = createAction('[NEWSLETTER BLOCKS] Create section', props<{ sectionType: SectionTypes }>());

export const insertSection = createAction(
	'[NEWSLETTER BLOCKS] Insert section',
	props<{ section: Section; index: number }>()
);

export const removeSection = createAction('[NEWSLETTER BLOCKS] Remove section by id', props<{ id: string }>());

export const duplicateSection = createAction('[NEWSLETTER BLOCKS] Duplicate section by id', props<{ id: string }>());

export const setSectionBackground = createAction(
	'[NEWSLETTER BLOCKS] Set section background',
	props<{ id: string; background: SectionBackground }>()
);

export const setSectionVerticalSpacing = createAction(
	'[NEWSLETTER BLOCKS] Set section vertical spacing',
	props<{ id: string; value: string }>()
);

export const reorderSection = createAction(
	'[NEWSLETTER BLOCKS] Reorder section',
	props<{ oldId: number; newId: number }>()
);

export const createBlock = createAction(
	'[NEWSLETTER BLOCKS] Create block',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const addBlock = createAction(
	'[NEWSLETTER BLOCKS] Add block',
	props<{
		block: Block;
		subsectionGuid: string;
		id: number;
	}>()
);

export const addNewsBlock = createAction(
	'[NEWSLETTER BLOCKS] Add news block',
	props<{
		channelId: string;
		newsId: string;
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const addEventBlock = createAction(
	'[NEWSLETTER BLOCKS] Add event block',
	props<{
		event: RhEvent;
		start: number;
		end: number;
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const reorderBlock = createAction(
	'[NEWSLETTER BLOCKS] Reorder block',
	props<{
		subsectionGuid: string;
		oldId: number;
		newId: number;
	}>()
);

export const moveBlock = createAction(
	'[NEWSLETTER BLOCKS] Move block between sections',
	props<{
		oldSubsectionGuid: string;
		newSubsectionGuid: string;
		oldId: number;
		newId: number;
	}>()
);

export const removeBlock = createAction(
	'[NEWSLETTER BLOCKS] Remove block',
	props<{
		subsectionGuid: string;
		blockGuid: string;
	}>()
);

export const duplicateBlock = createAction(
	'[NEWSLETTER BLOCKS] Duplicate block',
	props<{
		subsectionGuid: string;
		blockGuid: string;
		blockType: BlockTypes;
	}>()
);

export const updateBlockMarkup = createAction(
	'[NEWSLETTER BLOCKS] Update block markup',
	props<{
		blockGuid: string;
		markup: string;
	}>()
);

export const updateBlockSettings = createAction(
	'[NEWSLETTER BLOCKS] Update block settings',
	props<{
		blockGuid: string;
		settings: BlockProperties;
	}>()
);

// export const updateBlockSettings = createActionGroup({
//   source: '[NEWSLETTER BLOCKS - UPDATE]',
//   events: {
//     text: props<{blockGuid: string, settings: EmptyBlockProperties}>(),
//     divider: props<{blockGuid: string, settings: DividerBlockProperties}>(),
//     spacer: props<{blockGuid: string, settings: SpacerBlockProperties}>(),
//     image: props<{blockGuid: string, settings: EmptyBlockProperties}>(),
//     buttonLink: props<{blockGuid: string, settings: ButtonLinkBlockProperties}>(),
//     news: props<{blockGuid: string, settings: NewsBlockProperties}>(),
//     event: props<{blockGuid: string, settings: ButtonLinkBlockProperties}>(),
//     channelSection: props<{blockGuid: string, settings: ButtonLinkBlockProperties}>(),
//     survey: props<{blockGuid: string, settings: ButtonLinkBlockProperties}>(),
//   }
// });

export const updateNewsBlockMarkup = createAction(
	'[NEWSLETTER BLOCKS] Update news block markup',
	props<{
		blockGuid: string;
		flipped: boolean;
		card?: boolean;
		channelId: string;
		newsId: string;
	}>()
);

export const updateEventBlockMarkup = createAction(
	'[NEWSLETTER BLOCKS] Update event block markup',
	props<{
		blockGuid: string;
		flipped: boolean;
		card?: boolean;
		eventId: string;
		searchById: string;
	}>()
);

export const uploadBlockPhotoRequest = createAction(
	'[NEWSLETTER - BLOCKS] Upload block photo request',
	props<{ file: File; blockGuid: string; link?: string }>()
);

export const uploadBlockPhotoSuccess = createAction(
	'[NEWSLETTER - BLOCKS] Upload block photo success',
	props<{ photoUrl: string; photoName: string; blockGuid: string; link?: string }>()
);

export const uploadBlockPhotoFailure = createAction(
	'[NEWSLETTER - BLOCKS] Upload block photo response',
	props<{ message: any }>()
);

export const clearData = createAction('[NEWSLETTER - BLOCKS] Clear data');

export const setSections = createAction('[NEWSLETTER - BLOCKS] Set sections', props<{ sections: Array<Section> }>());

export const openEditNewsDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open edit news modal',
	props<{
		blockGuid: string;
	}>()
);

export const openSelectSurveyDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open select survey modal',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const openEditSurveyDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open edit survey modal',
	props<{
		blockGuid: string;
	}>()
);

export const openSelectNewsDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open select news modal',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const selectNewsDialogClosed = createAction(
	'[NEWSLETTER - BLOCKS] Select news modal closed',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const openEditEventDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open edit event modal',
	props<{
		blockGuid: string;
	}>()
);
export const openSelectEventDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open select event modal',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const selectEventDialogClosed = createAction(
	'[NEWSLETTER - BLOCKS] Select event modal closed',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const openEditChannelDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open edit channel modal',
	props<{
		blockGuid: string;
	}>()
);

export const openSelectChannelDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open select channel modal',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const selectChannelDialogClosed = createAction(
	'[NEWSLETTER - BLOCKS] Select channel modal closed',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const openEditAnchorsDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open edit anchors modal',
	props<{
		blockGuid: string;
	}>()
);

export const openTableOfContentDialog = createAction(
	'[NEWSLETTER - BLOCKS] Open select anchors modal',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const selectAnchorsDialogClosed = createAction(
	'[NEWSLETTER - BLOCKS] Select anchors modal closed',
	props<{
		blockType: BlockTypes;
		subsectionGuid: string;
		id: number;
	}>()
);

export const activateItem = createAction('[NEWSLETTER - BLOCKS] Set active item', props<{ itemGuid: string }>());

export const deactivateItem = createAction('[NEWSLETTER - BLOCKS] Set inactive item', props<{ itemGuid: string }>());

import { Pipe, PipeTransform } from '@angular/core';
import { Base64 } from 'js-base64';

export enum TagTypes {
	BUTTON = 0,
	CHANNEL = 1,
	NEWS = 2,
	EVENT = 3,
	LINK = 4,
	IMAGE = 5,
	SURVEY = 6,
}

@Pipe({
	name: 'getSesTags',
})
export class GetSesTagsPipe implements PipeTransform {
	transform(name: string, type: TagTypes): any {
		if (type < 0 || type > 6) {
			throw new Error('Incorrect type!');
		}
		let encodedName = Base64.encodeURI(name);
		return `type:${type};name:${encodedName};linkId:GUID_GOES_HERE`;
	}

	constructor() {}
}

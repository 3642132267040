<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content [formGroup]="feedbackSettings" style="overflow-x: hidden">
  <div class="row">
    <div class="col">
      <div class="mb-5">
        <p class="ui-label-m-bold">Select feedback type</p>
        <rds-form-field>
          <rds-control-label>Select option</rds-control-label>
          <rds-select formControlName="feedbackType">
            <rds-select-option *ngFor="let option of options" [value]="option.value">{{
              option.name
              }}</rds-select-option>
          </rds-select>
        </rds-form-field>
      </div>
      <ng-container *ngIf="feedbackSettings.get('feedbackType').value === this.answerType.PREDEFINED">
        <div class="mb-5">
          <p class="ui-label-m-bold">Contact email for feedback</p>
          <rds-form-field>
            <rds-control-label>Add contact email</rds-control-label>
            <input rdsInput formControlName="contact"/>
          </rds-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="feedbackSettings.get('feedbackType').value !== this.answerType.PREDEFINED">
        <div class="mb-5">
          <p class="ui-label-m-bold">Define Title of the feedback section(optional):</p>
          <rds-form-field>
            <rds-control-label>Title of the feedback</rds-control-label>
            <input rdsInput formControlName="title"/>
          </rds-form-field>
        </div>
        <div class="mb-5" *ngIf="feedbackSettings.get('feedbackType').value !== this.answerType.PREDEFINED">
          <p class="ui-label-m-bold">Define feedback question</p>
          <rds-form-field>
            <rds-control-label>Define question</rds-control-label>
            <input rdsInput formControlName="question"/>
          </rds-form-field>
        </div>

        <div
          class="px-8"
          formArrayName="answers"
          cdkDropList
          [cdkDropListData]="feedbackSettings.get('answers').controls"
          cdkDropListLockAxis="y"
          [cdkDropListDisabled]="feedbackSettings.get('answers').value.length < 2"
          (cdkDropListDropped)="dropAnswer($event)"
          *ngIf="feedbackSettings.get('feedbackType').value === this.answerType.CUSTOM"
        >
          <div cdkDrag cdkDragLockAxis="y" [cdkDragData]="control" cdkDragPreviewContainer="parent" class="mb-5"
               *ngFor="let control of feedbackSettings.get('answers').controls; let index = index">
            <div *ngIf="feedbackSettings.get('answers').value.length > 1" cdkDragHandle>
              <rds-icon icon="drag" class="c-600-roche-blue"></rds-icon>
            </div>
            <div class="row">
              <div class="col">
                <p class="ui-label-m">Define answer {{ index + 1 }}</p>
              </div>
              <div class="col-auto">
                <button
                  rds-icon-button
                  [disabled]="feedbackSettings.get('answers').controls.length < 2"
                  (click)="removeAnswer(index)"
                >
                  <rds-icon icon="delete" namespace="outlined"></rds-icon>
                </button>
              </div>
            </div>
            <rds-form-field>
              <rds-control-label>Define your answer</rds-control-label>
              <input rdsInput [formControl]="control"/>
            </rds-form-field>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-auto">
      <ng-container
        [ngSwitch]="feedbackSettings.get('feedbackType').value"
        *ngIf="feedbackSettings.get('feedbackType').value !== null"
      >
        <p class="ui-label-m-bold">Preview</p>
        <div style="width: 520px; pointer-events: none" class="bg-neutral-light-colour p-8">
          <div *ngSwitchCase="this.answerType.PREDEFINED">
            <ng-container *ngTemplateOutlet="feedbackHeader"></ng-container>
          </div>

          <div *ngSwitchCase="this.answerType.THUMBS">
            <ng-container *ngTemplateOutlet="feedbackHeader"></ng-container>
            <div style="display: flex; justify-content: center; gap: 24px">
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="like"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M20 7.4h-5V5a3 3 0 0 0-3-3H8.55v4.267L6.5 9H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h14.588a3 3 0 0 0 2.343-1.126l2.412-3.2A3 3 0 0 0 23 15.8v-5.4a3 3 0 0 0-3-3ZM6 20H3v-9h3Zm15-4.2a1 1 0 0 1-.219.625l-2.411 3.2a1 1 0 0 1-.781.375H8v-9.667l2.55-3.4V4H12a1 1 0 0 1 1 1v4.4h7a1 1 0 0 1 1 1Z"
                  ></path>
                </svg>
              </a>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="dislike"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M24 24H0V0h24z"></path>
                  <path
                    d="M4 16.6h5V19a3 3 0 0 0 3 3h3.45v-4.267L17.5 15H21a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6.561a3 3 0 0 0-2.342 1.125l-2.561 3.2A3 3 0 0 0 1 8.2v5.4a3 3 0 0 0 3 3ZM18 4h3v9h-3ZM3 8.2a1 1 0 0 1 .219-.625l2.561-3.2A1 1 0 0 1 6.561 4H16v9.667l-2.55 3.4V20H12a1 1 0 0 1-1-1v-4.4H4a1 1 0 0 1-1-1Z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <div *ngSwitchCase="this.answerType.STARS">
            <ng-container *ngTemplateOutlet="feedbackHeader"></ng-container>
            <div style="display: flex; justify-content: center; gap: 12px; direction: rtl">
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="star"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="m12 4.777 1.68 4.108.469 1.147 1.237.091 4.425.324-3.386 2.855-.949.8.3 1.207 1.06 4.3-3.777-2.339-1.053-.651-1.053.651-3.777 2.339 1.06-4.3.3-1.207-.95-.8L4.2 10.447l4.425-.324 1.237-.091.469-1.147 1.68-4.108m0-3.276a1.2 1.2 0 0 0-1.132.759l-2.4 5.869-6.334.464a1.219 1.219 0 0 0-.7 2.149l4.851 4.09-1.515 6.155a1.218 1.218 0 0 0 1.831 1.328l5.4-3.341 5.4 3.341a1.218 1.218 0 0 0 1.831-1.328l-1.515-6.155 4.852-4.09a1.219 1.219 0 0 0-.7-2.149l-6.334-.464-2.4-5.869A1.2 1.2 0 0 0 12 1.501Z"
                  ></path>
                </svg>
              </a>

              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="star"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="m12 4.777 1.68 4.108.469 1.147 1.237.091 4.425.324-3.386 2.855-.949.8.3 1.207 1.06 4.3-3.777-2.339-1.053-.651-1.053.651-3.777 2.339 1.06-4.3.3-1.207-.95-.8L4.2 10.447l4.425-.324 1.237-.091.469-1.147 1.68-4.108m0-3.276a1.2 1.2 0 0 0-1.132.759l-2.4 5.869-6.334.464a1.219 1.219 0 0 0-.7 2.149l4.851 4.09-1.515 6.155a1.218 1.218 0 0 0 1.831 1.328l5.4-3.341 5.4 3.341a1.218 1.218 0 0 0 1.831-1.328l-1.515-6.155 4.852-4.09a1.219 1.219 0 0 0-.7-2.149l-6.334-.464-2.4-5.869A1.2 1.2 0 0 0 12 1.501Z"
                  ></path>
                </svg>
              </a>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="star"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="m12 4.777 1.68 4.108.469 1.147 1.237.091 4.425.324-3.386 2.855-.949.8.3 1.207 1.06 4.3-3.777-2.339-1.053-.651-1.053.651-3.777 2.339 1.06-4.3.3-1.207-.95-.8L4.2 10.447l4.425-.324 1.237-.091.469-1.147 1.68-4.108m0-3.276a1.2 1.2 0 0 0-1.132.759l-2.4 5.869-6.334.464a1.219 1.219 0 0 0-.7 2.149l4.851 4.09-1.515 6.155a1.218 1.218 0 0 0 1.831 1.328l5.4-3.341 5.4 3.341a1.218 1.218 0 0 0 1.831-1.328l-1.515-6.155 4.852-4.09a1.219 1.219 0 0 0-.7-2.149l-6.334-.464-2.4-5.869A1.2 1.2 0 0 0 12 1.501Z"
                  ></path>
                </svg>
              </a>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="star"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="m12 4.777 1.68 4.108.469 1.147 1.237.091 4.425.324-3.386 2.855-.949.8.3 1.207 1.06 4.3-3.777-2.339-1.053-.651-1.053.651-3.777 2.339 1.06-4.3.3-1.207-.95-.8L4.2 10.447l4.425-.324 1.237-.091.469-1.147 1.68-4.108m0-3.276a1.2 1.2 0 0 0-1.132.759l-2.4 5.869-6.334.464a1.219 1.219 0 0 0-.7 2.149l4.851 4.09-1.515 6.155a1.218 1.218 0 0 0 1.831 1.328l5.4-3.341 5.4 3.341a1.218 1.218 0 0 0 1.831-1.328l-1.515-6.155 4.852-4.09a1.219 1.219 0 0 0-.7-2.149l-6.334-.464-2.4-5.869A1.2 1.2 0 0 0 12 1.501Z"
                  ></path>
                </svg>
              </a>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="star"
                  width="24px"
                  height="24px"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="m12 4.777 1.68 4.108.469 1.147 1.237.091 4.425.324-3.386 2.855-.949.8.3 1.207 1.06 4.3-3.777-2.339-1.053-.651-1.053.651-3.777 2.339 1.06-4.3.3-1.207-.95-.8L4.2 10.447l4.425-.324 1.237-.091.469-1.147 1.68-4.108m0-3.276a1.2 1.2 0 0 0-1.132.759l-2.4 5.869-6.334.464a1.219 1.219 0 0 0-.7 2.149l4.851 4.09-1.515 6.155a1.218 1.218 0 0 0 1.831 1.328l5.4-3.341 5.4 3.341a1.218 1.218 0 0 0 1.831-1.328l-1.515-6.155 4.852-4.09a1.219 1.219 0 0 0-.7-2.149l-6.334-.464-2.4-5.869A1.2 1.2 0 0 0 12 1.501Z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <div *ngSwitchCase="this.answerType.CUSTOM">
            <ng-container *ngTemplateOutlet="feedbackHeader"></ng-container>

            <rds-radio-group class="d-flex flex-column" [formControl]="previewRadio">
              <rds-radio-button *ngFor="let answer of feedbackSettings.get('answers').value">
                {{ answer || 'Define your answer' }}</rds-radio-button
              >
            </rds-radio-group>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <button
    *ngIf="feedbackSettings.get('feedbackType').value === this.answerType.CUSTOM"
    rds-text-button
    (click)="addNewAnswer()"
    style="justify-self: center; margin-right: auto"
  >
    Add next answer
  </button>
  <button rds-secondary-button [rds-dialog-close]="null" size="m">{{ data.cancelButtonLabel || 'Cancel' }}</button>
  <button rds-primary-button (click)="onConfirm()" size="m" [disabled]="feedbackSettings.invalid">
    {{ data.confirmButtonLabel || 'Confirm' }}
  </button>
</div>

<ng-template #feedbackHeader>
  <ng-container *ngIf="feedbackSettings.get('feedbackType').value === this.answerType.PREDEFINED">
    <p class="ui-body-l-bold mb-4">
      {{ predefinedTitle }}
    </p>
    <p class="ui-body-m-bold mb-8">{{ predefinedQuestion }}
      <span
        *ngIf="feedbackSettings.get('feedbackType').value === this.answerType.PREDEFINED"> Reach&nbsp;us&nbsp;at:&nbsp;{{ feedbackSettings.get('contact').value || 'yourcontact.email@roche.com' }}</span>
    </p>
  </ng-container>
  <ng-container *ngIf="feedbackSettings.get('feedbackType').value !== 0">
    <p class="ui-body-l-bold mb-4"
       *ngIf="feedbackSettings.get('title').value">{{ feedbackSettings.get('title').value }}</p>
    <p
      class="ui-body-m-bold mb-8">{{ feedbackSettings.get('question').value || 'Your feedback question goes here' }}</p>
  </ng-container>
</ng-template>

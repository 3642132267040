import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { NewsletterService } from '@app/core/services/newsletter.service';
import * as fromReducer from './mock.reducer';

@Injectable()
export class MockEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsletterService: NewsletterService,
  ) {
  }
}

import { NewsModelNew } from '@app/core/models/news.model';
import {
	DEFAULT_SECTION_BACKGROUND,
	Section,
	SectionTypes,
	SubsectionTypes,
} from '@app/newsletter-new/models/section-type.enum';
import {
	AnchorBlockProperties,
	BlockTypes,
	ButtonLinkBlockProperties,
	ChannelBlockProperties,
	DividerBlockProperties,
	EventBlockProperties,
	NewsBlockProperties,
	SpacerBlockProperties,
	SurveyBlockProperties,
} from '../models/block-type.enum';
import { PeriodEnum } from '@app/shared/filters/models/newsletter-link-statistics';
import { ANSWERTYPE } from '../dialogs/manage feedback-section-dialog/manage feedback-section-dialog.component';

export const ANSWERS_DELIMITER = ';answer;';
export const BLOCK_MARKUP_TO_SETTINGS: { [key: string]: (markup: string) => { [key: string]: any } } = {
	[BlockTypes.TEXT]: (markup) => {
		return {};
	},
	[BlockTypes.ANCHOR]: (markup) => {
		return {
			label: /data-label="(?<label>.*?)"/.exec(markup)?.groups?.label,
		} as AnchorBlockProperties;
	},
	[BlockTypes.DIVIDER]: (markup) => {
		return {
			spacing: /margin: (?<spacing>.*?);"/.exec(markup)?.groups?.spacing,
		} as DividerBlockProperties;
	},
	[BlockTypes.SPACER]: (markup) => {
		return {
			spacing: /margin: (?<spacing>.*?);"/.exec(markup)?.groups?.spacing,
		} as SpacerBlockProperties;
	},
	[BlockTypes.IMAGE]: (markup) => {
		return {
			photoUrl: /src="(?<photoUrl>.*?)"/.exec(markup)?.groups?.photoUrl,
			photoName: /data-photoname="(?<photoName>.*?)"/.exec(markup)?.groups?.photoName,
			link: /href="(?<link>.*?)"/.exec(markup)?.groups?.link,
		};
	},
	[BlockTypes.BUTTON_LINK]: (markup) => {
		return {
			type: /data-buttontype="(?<type>.*?)"/.exec(markup)?.groups?.type,
			position: /data-position="(?<position>.*?)"/.exec(markup)?.groups?.position,
			link: /href="(?<link>.*?)"/.exec(markup)?.groups?.link,
			text: /> (?<text>.*?) </.exec(markup)?.groups?.text,
		} as ButtonLinkBlockProperties;
	},
	[BlockTypes.NEWS]: (markup) => {
		const newsLanguage = /data-newslanguage="(?<newsLanguage>.*?)"/.exec(markup)?.groups?.newsLanguage;
		return {
			news: {
				newsId: /data-newsid="(?<newsId>news:.*?)"/.exec(markup)?.groups?.newsId,
				abstracts: {
					[newsLanguage]: {
						abstract: /<p.*?>(?<abstract>.*?)<\/p>/.exec(markup)?.groups?.abstract,
						imgUrl: /url\(&quot;(?<imageUrl>.*?)&quot;\)/.exec(markup)?.groups?.imageUrl,
						languageCode: newsLanguage,
						title: /<h3.*?>(?<title>.*?)<\/h3>/.exec(markup)?.groups?.title,
						url: /href="(?<url>.*?)"/.exec(markup)?.groups?.url,
					},
				},
				channelId: /data-newsid="(?<channelId>news:.*?):.*?"/.exec(markup)?.groups?.channelId,
				primaryLanguage: newsLanguage,
				type: 'link',
			},
			card: /data-card="(?<card>.*?)"/.exec(markup)?.groups?.card === 'true',
			flipped: /data-flipped="(?<flipped>.*?)"/.exec(markup)?.groups?.flipped === 'true',
			newsLanguage,
		} as NewsBlockProperties;
	},
	[BlockTypes.EVENT]: (markup) => {
		let timeLabel = /<span style="float: left;".*?>(?<timeLabel>.*?)<\/span>/.exec(markup)?.groups?.timeLabel;
		let dateLabel = /<span style="background-color: #7(D|d)0096;.*?".*?>(?<dateLabel>.*?)<\/span>/.exec(markup)?.groups
			?.dateLabel;
		let date = new Date(Date.parse(dateLabel + (timeLabel === 'ALL DAY' ? '' : (' ' + timeLabel))));
		return {
			event: {
				id: +/data-eventid="(?<eventId>.*?)"/.exec(markup)?.groups?.eventId,
				imageUrl: /url\(&quot;(?<imageUrl>.*?)&quot;\)/.exec(markup)?.groups?.imageUrl,
				eventUrl: /href="(?<url>.*?)"/.exec(markup)?.groups?.url,
				title: /<h3.*?>(?<title>.*?)<\/h3>/.exec(markup)?.groups?.title,
				allDay: timeLabel === 'ALL DAY',
				date,
			},
			card: /data-card="(?<card>.*?)"/.exec(markup)?.groups?.card === 'true',
			flipped: /data-flipped="(?<flipped>.*?)"/.exec(markup)?.groups?.flipped === 'true',
			start: null,
			end: null,
		} as EventBlockProperties;
	},
	[BlockTypes.CHANNEL]: (markup) => {
		const channelId = /data-news="(?<channelId>news:.*?):/.exec(markup)?.groups?.channelId;
		const newsIdLang = /data-news="(?<newsIds>.*?)"/.exec(markup)?.groups?.newsIds.split(',');
		return {
			addLink: /data-addlink="(?<addLink>.*?)"/.exec(markup)?.groups?.addLink === 'true',
			linkText: /data-linktext="(?<linkText>.*?)"/.exec(markup)?.groups?.linkText,
			card: /data-card="(?<card>.*?)"/.exec(markup)?.groups?.card === 'true',
			channel: {
				id: channelId,
				name: /data-channelname="(?<channelName>.*?)"/.exec(markup)?.groups?.channelName,
			},
			selectedNews: newsIdLang.map((idLang) => {
				const newsId = /(?<newsId>news:.*?:.*?):/.exec(idLang)?.groups?.newsId;
				const language = /news:.*?:.*?:(?<language>.*?)$/.exec(idLang)?.groups?.language;
				const newsContentExpression = `(?<newsContent>data-newsid="${newsId}".*?<\/table>)`;
				const newsContent = new RegExp(newsContentExpression).exec(markup)?.groups?.newsContent;
				return {
					language,
					news: {
						newsId,
						primaryLanguage: language,
						channelId,
						type: 'link',
						abstracts: {
							[language]: {
								abstract: /<p.*?>(?<abstract>.*?)<\/p>/.exec(newsContent)?.groups?.abstract,
								imgUrl: /url\(&quot;(?<imageUrl>.*?)&quot;\)/.exec(newsContent)?.groups?.imageUrl,
								languageCode: language,
								title: /<h3.*?>(?<title>.*?)<\/h3>/.exec(newsContent)?.groups?.title,
								url: /href="(?<url>.*?)"/.exec(newsContent)?.groups?.url,
							},
						},
					},
				};
			}),
			selectionType: 'manual',
		} as ChannelBlockProperties;
	},
	[BlockTypes.SURVEY]: (markup) => {
		return {
			surveyType: +(/data-surveytype="(?<surveyType>.*?)"/.exec(markup)?.groups?.surveyType as any) as ANSWERTYPE,
			question: /data-question="(?<question>.*?)"/.exec(markup)?.groups?.question,
			answers: /data-answers="(?<answers>.*?)"/.exec(markup)?.groups?.answers.split(ANSWERS_DELIMITER),
		} as SurveyBlockProperties;
	},
};

export function createSection(sectionType: SectionTypes) {
	let section: Section;
	switch (sectionType) {
		case SectionTypes.SINGLE: {
			section = {
				id: createGuid(),
				type: sectionType,
				background: DEFAULT_SECTION_BACKGROUND,
				verticalSpace: '16px',
				subsections: [
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_100,
						blocks: [],
					},
				],
			};
			break;
		}
		case SectionTypes.TWO_EQUAL: {
			section = {
				id: createGuid(),
				type: sectionType,
				background: DEFAULT_SECTION_BACKGROUND,
				verticalSpace: '16px',
				subsections: [
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_50,
						blocks: [],
					},
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_50,
						blocks: [],
					},
				],
			};
			break;
		}
		case SectionTypes.THREE_EQUAL: {
			section = {
				id: createGuid(),
				type: sectionType,
				background: DEFAULT_SECTION_BACKGROUND,
				verticalSpace: '16px',
				subsections: [
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_33,
						blocks: [],
					},
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_33,
						blocks: [],
					},
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_33,
						blocks: [],
					},
				],
			};
			break;
		}
		case SectionTypes.THREE_IN_ONE: {
			section = {
				id: createGuid(),
				type: sectionType,
				background: DEFAULT_SECTION_BACKGROUND,
				verticalSpace: '16px',
				subsections: [
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_66,
						blocks: [],
					},
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_33,
						blocks: [],
					},
				],
			};
			break;
		}
		case SectionTypes.ONE_IN_THREE: {
			section = {
				id: createGuid(),
				type: sectionType,
				background: DEFAULT_SECTION_BACKGROUND,
				verticalSpace: '16px',
				subsections: [
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_33,
						blocks: [],
					},
					{
						id: createGuid(),
						colspan: SubsectionTypes.PERCENT_66,
						blocks: [],
					},
				],
			};
			break;
		}
	}
	return section;
}

/* tslint:disable */
export function createGuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function generateImageMarkup(photoUrl: string, linkUrl: string) {
	const aOpen = `<a href='${linkUrl}' target='_blank'>`;
	const aClose = '</a>';
	const img = `<img class='email-block__image' style='display: block; width: 100%; border-radius: 4px; margin-bottom: 16px;' crossorigin="anonymous" src='${photoUrl}'>`;
	return linkUrl && linkUrl.length ? aOpen + img + aClose : img;
}

export function generateNewsCardMarkup(news: NewsModelNew) {
	let textDiv: string;
	let imgDiv: string;
	let newsHTML: string;
	imgDiv = `
  <div style='width: 100%'>
    <div style='width: 100%; padding-top: 56.25%; background-size:cover; margin-bottom: 12px; border-radius: 4px; border: 1px solid #FAD6C7; background-image: url(${news.imageUrl || createPlaceholderUrl()})' data-nbimg></div>
  </div>`;

	textDiv = `
    <div style='margin-bottom: 16px; width: 100%;'>
      <a style='text-decoration: none;' target='_blank' href='${news.linkUrl}'>
        <h3 style='color: #1E1E1E; font-size: 14px; font-weight: 600; letter-spacing: 0.2px; line-height: 21px; margin-top: 0; margin-bottom: 4px;'>
          ${news.title}
        </h3>
      </a>
      <p style='color: #1E1E1E; font-size: 14px; font-weight: 400; letter-spacing: 0.2px; line-height: 21px; margin: 0;'>
        ${news.description}
      </p>
    </div>`;
	newsHTML = imgDiv + textDiv;
	return `
    <div data-newsid='${news.newsId}' style='margin-bottom: 24px; display: flex; flex-direction: column;'>
      ${newsHTML}
    </div>
  `;
}

export function generateNewsListMarkup(news: NewsModelNew, flipped = false) {
	let textDiv: string;
	let imgDiv: string;
	let newsHTML: string;
	imgDiv = `
  <div style='margin-${flipped ? 'left' : 'right'}: 16px; width: 160px; flex-shrink: 0;'>
    <div style='width: 100%; padding-top: 56.25%; background-size:cover; border-radius: 2px; background-image: url(${news.imageUrl || createPlaceholderUrl()})' data-nbimg></div>
  </div>`;

	textDiv = `
    <div style='padding-bottom: 8px; width: 100%;'>
        <a style='text-decoration: none;' target='_blank' href='${news.linkUrl}'>
          <h3 style='color: #1E1E1E; font-size: 14px; font-weight: 600; letter-spacing: 0.2px; line-height: 21px; margin-top: 0; margin-bottom: 4px;'>
            ${news.title}
          </h3>
        </a>
      <p style='color: #1E1E1E; font-size: 14px; font-weight: 400; letter-spacing: 0.2px; line-height: 21px; margin: 0;'>
        ${news.description}
      </p>
    </div>`;
	newsHTML = flipped ? textDiv + imgDiv : imgDiv + textDiv;
	return `
    <div data-newsid='${news.newsId}' style='margin-bottom: 16px; display: flex;'>
      ${newsHTML}
    </div>
  `;
}

export function createPlaceholderUrl(): string {
	return location.origin + '/assets/images/news-placeholder.png';
}
/* tslint:enable */

export function periodFromDate(t: PeriodEnum, d: Date) {
	const date = new Date(d);
	switch (t) {
		case PeriodEnum.YEAR:
			return date.getFullYear() + '';
		case PeriodEnum.MONTH:
			return `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}`;
		case PeriodEnum.DAY:
			return `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}-${(date.getDate() + '').padStart(2, '0')}`;
		case PeriodEnum.HOUR:
			return `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}-${(date.getDate() + '').padStart(2, '0')}T${(date.getHours() + '').padStart(2, '0')}:00:00`;
	}
}

export function subperiodsFromPeriod(period: string) {
	const [year, month, day] = period.split('-');
	console.log(year, month, day);
	let subperiods = [];
	if (day && month && year) {
		let currentDate = new Date(`${period}T00:00:00`);
		while (+day === currentDate.getDate()) {
			subperiods.push(periodFromDate(PeriodEnum.HOUR, currentDate));
			currentDate = new Date(currentDate.setHours(currentDate.getHours() + 1));
		}
		return subperiods;
	}
	if (month && year) {
		let currentDate = new Date(`${period}-01`);
		while (+month === currentDate.getMonth() + 1) {
			subperiods.push(periodFromDate(PeriodEnum.DAY, currentDate));
			currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
		}
		return subperiods;
	}
	let currentDate = new Date(`${period}-01`);
	while (+year === currentDate.getFullYear()) {
		subperiods.push(periodFromDate(PeriodEnum.MONTH, currentDate));
		currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
	}
	return subperiods;
}

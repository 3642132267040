import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GlobalSettingsContainer} from '@app/user-settings/containers/global-settings/global-settings.container';
import {NotificationsContainer} from './containers/notifications/notifications.container';
import {ContainerComponent} from 'src/app/layout/components';
import {SettingsPageContainer} from '@app/user-settings/containers/settings-page/settings-page.container';
import {IsDesktopGuard} from '@app/core/auth/guards/isDesktop.guard';
import {SubscriptionsContainer} from '@app/user-settings/containers/subscriptions/subscriptions.container';

export const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: '',
        component: SettingsPageContainer,
        data: {title: 'RocheHome - My Settings'},
        children: [
          {
            path: '',
            redirectTo: 'global',
            pathMatch: 'full'
          },
          {
            path: 'global',
            component: GlobalSettingsContainer,
            data: {title: 'RocheHome - Global Settings'},
          },
          {
            path: 'notifications',
            component: NotificationsContainer,
            canActivate: [IsDesktopGuard],
            data: {title: 'RocheHome - Notifications Settings'},
          },
          {
            path: 'subscriptions',
            component: SubscriptionsContainer,
            canActivate: [IsDesktopGuard],
            data: {title: 'RocheHome - Campaign Subscriptions'},
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule]
})
export class UserSettingsRoutingModule {
}

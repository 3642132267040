import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './newsletter-preview.actions';

export interface State {
	isSubscribed: boolean;
}

export const initialState: State = {
	isSubscribed: null
};

export const newsletterPreviewReducer = createReducer(
	initialState,
	on(fromActions.subscription.isSubscribedSuccess, (state, {isSubscribed}) => ({
		...state,
		isSubscribed
	})),
	on(fromActions.subscription.subscribeSuccess, (state) => ({
		...state,
		isSubscribed: true,
	})),
	on(fromActions.subscription.unsubscribeSuccess, (state) => ({
		...state,
		isSubscribed: false,
	})),
	on(fromActions.clear, (state) => ({
		...initialState,
	})),
);

export function reducer(state: State | undefined, action: Action) {
	return newsletterPreviewReducer(state, action);
}

export const getIsSubscribed = (state: State) => state.isSubscribed;

import { createSelector } from '@ngrx/store';
import * as fromReducer from './campaign-subscriptions.reducer';
import * as fromUserSettings from '@app/user-settings/store/user-settings.reducer';

export const selectSubscriptions = createSelector(
  fromUserSettings.selectState,
  fromUserSettings.getCampaignSubscriptions,
);


export const selectData = createSelector(
  selectSubscriptions,
  fromReducer.getData
);
